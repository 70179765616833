


















import { Vue, Component } from "vue-property-decorator";

import * as Types from "@/store/Types";

@Component
export default class Login extends Vue {
    private token: string = "";
    private error: string = "";

    public async autorize() {
        try {
            const res = await this.$Api.Auth.Login(this.token);
            if (res.status !== "OK") throw new Error(res.message);

            await this.$MStore.User.SetAuth({ Token: this.token, Permissions: res.permissions! });

            this.$router.push({ name: "home" });
        } catch (error) {
            this.error = "Ошибка авторизации";
        }
    }
}
