var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c(
      "div",
      { staticClass: "login-form" },
      [
        _c("el-input", {
          attrs: {
            label: "Введите токен для авторизации...",
            placeholder: "token",
          },
          model: {
            value: _vm.token,
            callback: function ($$v) {
              _vm.token = $$v
            },
            expression: "token",
          },
        }),
        _c(
          "el-button",
          {
            staticClass: "login-btn",
            on: {
              click: function ($event) {
                return _vm.autorize()
              },
            },
          },
          [_vm._v(" Авторизация ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }